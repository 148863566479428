import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import SImage from 'gatsby-plugin-sanity-image'
import { theme } from '@/styles/theme'

interface Props {
  image: any
  objectFit?: string
  altText?: string
  imageWidth?: number | string
  imageHeight?: number | string
  width: number | string
  height?: number | string
  maxHeight?: string | { xs: number | string; sm: number | string }
  aspectRatio?: number
}

const IMG = styled(SImage)`
  width: ${p => p.imageWidth || '100%'};
  ${p => typeof p.maxHeight === 'string' && 'max-height: ' + p.maxHeight + ';'}
  ${p => {
    if (typeof p.maxHeight === 'object') {
      return (
        'max-height: ' +
        p.maxHeight.xs +
        ';' +
        '@media screen and (min-width: ' +
        theme.breakpoints.values.sm +
        'px) {' +
        'max-height: ' +
        p.maxHeight.sm +
        ';'
      )
    }
  }}

  height: ${p => p.imageHeight || '100%'};
  object-fit: ${p => p.objectFit || 'cover'};
  bottom: 50px;
  right: 50px;
  ${p => p.aspectRatio && 'aspect-ratio: ' + p.aspectRatio + ';'}
`

const SanityImage: FC<Props> = props => {
  const {
    image,
    altText,
    width,
    imageWidth,
    imageHeight,
    maxHeight,
    objectFit,
    aspectRatio
  } = props

  return (
    <IMG
      {...image}
      altText={altText}
      width={width}
      maxHeight={maxHeight}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      objectFit={objectFit}
      aspectRatio={aspectRatio}
    />
  )
}

export default SanityImage

export const query = graphql`
  fragment Image on SanityImage {
    asset {
      _id
      altText
      originalFilename
      metadata {
        lqip
        dimensions {
          aspectRatio
          width
          height
        }
      }
      url
    }
  }

  fragment imageWithAlt on SanityImageWithAlt {
    asset {
      _id
      altText
      originalFilename
      metadata {
        lqip
        dimensions {
          aspectRatio
          width
          height
        }
      }
      url
    }
    subtitle
    subtitleAsDownloadLink
  }
`
