import { useState, useEffect } from 'react'

interface WindowDimensions {
  width: number | undefined
  height: number | undefined
}

const windowDimensionsDefault = {
  width: undefined,
  height: undefined
}

function getWindowDimensions() {
  if (!window) return windowDimensionsDefault
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(
    windowDimensionsDefault
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    setWindowDimensions(getWindowDimensions())
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
